import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

import Button from "../elements/Button";
import BrandIcon from "./BrandIcon";

export default function Header() {
  const [isCollapse, setIsCollapse] = useState(false);
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-70YLNQ46V1";
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-70YLNQ46V1');
    `;
    document.head.appendChild(script2);

    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  return (
    <header className="header">
      <div className="flex justify-between px-4 lg:px-0">
        <BrandIcon />
        <button
          aria-expanded={isCollapse}
          aria-label="Toggle Navigation"
          className="block text-theme-blue lg:hidden focus:outline-none"
          onClick={() => setIsCollapse(!isCollapse)}
        >
          <svg
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isCollapse ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
            />
          </svg>
        </button>
      </div>

      <ul className="hidden lg:flex items-center text-theme-blue tracking-widest">
        {["/", "/project"].map((route, index) => (
          <li key={index}>
            <Button
              className={clsx(
                "font-medium text-lg px-5 no-underline hover:underline",
                { "active-link": path === route },
              )}
              type="link"
              href={route}
            >
              {route.replace("/", "") || "Home"}
            </Button>
          </li>
        ))}
        <li>
          <Button
            className="font-medium text-lg px-6 py-2 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple transition duration-200"
            type="link"
            href="/discuss-project"
          >
            Discuss Project
          </Button>
        </li>
      </ul>

      <Transition
        show={isCollapse}
        enter="transition-opacity duration-400"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-400"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <ul className="flex flex-col text-theme-blue tracking-widest my-6 bg-white absolute w-full border-b-2 border-gray-300 lg:hidden">
          {["/", "/project"].map((route, index) => (
            <li className="py-2 bg-white" key={index}>
              <Button
                className={clsx(
                  "font-medium px-10 no-underline hover:underline",
                  { "active-link": path === route },
                )}
                type="link"
                href={route}
              >
                {route.replace("/", "") || "Home"}
              </Button>
            </li>
          ))}
          <li className="mx-auto my-9 bg-white">
            <Button
              className="font-bold px-5 py-2 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple transition duration-200"
              type="link"
              href="/discuss-project"
            >
              Discuss Project
            </Button>
          </li>
        </ul>
      </Transition>
    </header>
  );
}
