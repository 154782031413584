/* eslint-disable import/extensions */

// Services Img Imports
import Mobile from "../assets/images/Services/Web_Development.png";
import UIUX from "../assets/images/Services/UIUX_Flow.png";
import Intergration from "../assets/images/Services/Integration.png";
// Portfolio Img Imports
import Recruiting from "../assets/images/Portfolio/AI_Chatbot.png";
import Stream from "../assets/images/Portfolio/ClassE365_Integrations.png";
import Freelance from "../assets/images/Portfolio/Custom_Wordpress.png";
import Aura from "../assets/images/Portfolio/Implementation_E2E.png";
import Surtido from "../assets/images/Portfolio/E-com_Store.png";
import ManagementApp from "../assets/images/Portfolio/CRM.png";

// Advantages
import Communicative from "../assets/images/Advantages/Communicative_1.png";
import Collaborative from "../assets/images/Advantages/Collab.png";
import Management from "../assets/images/Advantages/Management.png";
import Favorite from "../assets/images/Advantages/Accuracy.png";

// Testimonials

// TeamMembers
import CEO from "../assets/images/TeamMembers/CEO.jpg";
import HRD from "../assets/images/TeamMembers/HRD.jpg";
import Finance from "../assets/images/TeamMembers/Finance.jpg";
import ProjectManager from "../assets/images/TeamMembers/Project-manager.jpg";
import Frontend1 from "../assets/images/TeamMembers/Frontend1.jpg";
import Frontend2 from "../assets/images/TeamMembers/Frontend2.jpg";
import Backend1 from "../assets/images/TeamMembers/Backend1.jpg";
import Backend2 from "../assets/images/TeamMembers/Backend2.jpg";
import Mobile1 from "../assets/images/TeamMembers/Mobile1.jpg";
import Mobile2 from "../assets/images/TeamMembers/Mobile2.jpg";
import UIUX1 from "../assets/images/TeamMembers/UIUX1.jpg";
import UIUX2 from "../assets/images/TeamMembers/UIUX2.jpg";

export const Services = [
  {
    title: "Integration Solution",
    imageUrl: Intergration,
    animation: "left",
  },
  {
    title: "WebApp Development",
    imageUrl: Mobile,
    animation: "up",
  },
  {
    title: "UI/UX Design",
    imageUrl: UIUX,
    animation: "right",
  },
];

export const Portfolios = [
  {
    id: "asd1293ufgdfgs4",
    title: "End to End Solutions",
    imageUrl: Aura,
    type: "ERP",
    responsibility: ["Web Development", "UI/UX Design"],
  },
  {
    id: "asd1293ulskmnb5",
    title: "E-commerce Store",
    imageUrl: Surtido,
    type: "Website",
    responsibility: ["Web Development", "UI/UX Design"],
  },
  {
    id: "asd1293ulkmnbj6",
    title: "Customer Relationship Managment Software",
    imageUrl: ManagementApp,
    type: "Website",
    responsibility: ["Web Development", "UI/UX Design"],
  },
  {
    id: "asd1293uasdads1",
    title: "AI ChatBot",
    imageUrl: Recruiting,
    type: "Integration",
    responsibility: ["Mobile Development", "UI/UX Design"],
  },
  {
    id: "asd1293uhjkhkjh2",
    title: "ClassE365 Enhancements",
    imageUrl: Stream,
    type: "Middleware Development",
    responsibility: ["Mobile Development", "UI/UX Design"],
  },
  {
    id: "asd1293uvbvcbbd3",
    title: "Wordpress Customizations",
    imageUrl: Freelance,
    type: "Middleware Development",
    responsibility: ["Mobile Development", "UI/UX Design"],
  },
];

export const Advantages = [
  [
    {
      title: "Communicative",
      description:
        "We articulate project ideas and updates clearly to ensure mutual understanding.",
      imageUrl: Communicative,
    },
    {
      title: "Management",
      description:
        "We effectively manage our projects to ensure they are completed successfully.",
      imageUrl: Management,
    },
  ],
  [
    {
      title: "Collaborative",
      description:
        "Our team collaborates seamlessly to ensure project success.",
      imageUrl: Collaborative,
    },
    {
      title: "Accuracy",
      description:
        "Our projects are executed with precision, ensuring client satisfaction.",
      imageUrl: Favorite,
    },
  ],
];
export const Testimonials = [
  {
    id: 1,
    name: "Sasha Rose",
    company: "Owner, Surveyor Corps",
    testimoni:
      "Thanks for Teach Ahead, you guys are the best! Keep up the great work!",
  },
  {
    id: 2,
    name: "Kruger Khan",
    company: "Director, Shultan Oil",
    testimoni:
      "I just wanted to let you know that it’s been great working with Teach Ahead.",
  },
  {
    id: 3,
    name: "Reiner John",
    company: "CEO, Marley CO",
    testimoni:
      "Teach Ahead is so great. Thank you so much for a job well done.",
  },
];

export const TeamMembers = [
  {
    name: "Rach David",
    position: "CEO",
    imageUrl: CEO,
  },
  {
    name: "Pauline Sydney",
    position: "HRD",
    imageUrl: HRD,
  },
  {
    name: "Granger Watterson",
    position: "Finance",
    imageUrl: Finance,
  },
  {
    name: "Tom Jimmy",
    position: "Project Manager",
    imageUrl: ProjectManager,
  },
  {
    name: "Jim Hendrix",
    position: "Front-end Developer",
    imageUrl: Frontend1,
  },
  {
    name: "Calvin Max",
    position: "Front-end Developer",
    imageUrl: Frontend2,
  },
  {
    name: "Hawkins Jim",
    position: "Back-end Developer",
    imageUrl: Backend1,
  },
  {
    name: "Don Bizaro",
    position: "Back-end Developer",
    imageUrl: Backend2,
  },
  {
    name: "Bill Markinson",
    position: "Mobile Developer",
    imageUrl: Mobile1,
  },
  {
    name: "Igor Kavarov",
    position: "Mobile Developer",
    imageUrl: Mobile2,
  },
  {
    name: "Freddie Curl",
    position: "UI/UX Designer",
    imageUrl: UIUX2,
  },
  {
    name: "Monica Lovegood",
    position: "UI/UX Designer",
    imageUrl: UIUX1,
  },
];
