/* eslint-disable max-len */
import { Stars } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { useEffect } from "react";
// import { FiArrowRight } from "react-icons/fi";
import { FaGlobe } from "react-icons/fa";
import {
  useMotionTemplate,
  useMotionValue,
  motion,
  animate,
} from "framer-motion";
import Typewriter from "typewriter-effect";

const COLORS_TOP = ["#3d52a0", "#7091e6", "#8697c4", "#ede8f5"];

export default function HeroAnimation() {
  const color = useMotionValue(COLORS_TOP[0]);

  useEffect(() => {
    animate(color, COLORS_TOP, {
      ease: "easeInOut",
      duration: 10,
      repeat: Infinity,
      repeatType: "mirror",
    });
  }, []);

  const backgroundImage = useMotionTemplate`radial-gradient(125% 145% at 50% 0%, #3d52a0 40%, ${color})`;
  // const border = useMotionTemplate`1px solid ${color}`;
  // const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;

  return (
    <motion.section
      style={{
        backgroundImage,
      }}
      className="min-h-screen overflow-hidden bg-gray-950 md:text-gray-200 text-gray-100"
    >
      <div className="relative flex items-center min-h-screen justify-around pt-10 z-10 md:flex-row flex-col">
        <div className="w-full md:w-[50%] px-4">
          <h1 className="bg-gradient-to-br from-white to-gray-400 bg-clip-text text-center text-2xl font-medium leading-tight text-transparent sm:text-3xl md:text-5xl md:leading-tight">
            <Typewriter
              options={{
                strings: ["Your Vision, Our Technology"],
                autoStart: true,
                loop: true,
                deleteSpeed: 50,
              }}
            />
          </h1>
          <p className="my-6  text-center text-base leading-relaxed sm:text-sm md:text-lg md:leading-relaxed">
            At TechAhead, we are dedicated to empowering you to achieve any
            targets you set. Our expert IT solutions optimize operations,
            ensuring improved visibility and conversions. Plus, with AI
            integration, tasks become smarter and faster.
          </p>
        </div>
        <div className="">
          <FaGlobe
            size={300}
            className="text-white  rotate" // Apply the rotate class here
          />
        </div>
      </div>

      <div className="absolute inset-0 z-0">
        <Canvas>
          <Stars radius={40} count={2500} factor={4} fade speed={4} />
        </Canvas>
      </div>
    </motion.section>
  );
}
