import React, { Component } from "react";

import {
  Services,
  Portfolios,
  Advantages,
  Testimonials,
} from "json/landingPageData";
import Header from "parts/Header";
import Hero from "parts/Hero";
import Service from "parts/Service";
import Portfolio from "parts/Portfolio";
import Advantage from "parts/Advantage";
import Testimonial from "parts/Testimonial";
import Discuss from "parts/Discuss";
import Footer from "parts/Footer";

export default class LandingPage extends Component {
  state = {
    isMobile: window.innerWidth <= 768,
  };

  updateScreenSize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.updateScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
  }

  render() {
    const { isMobile } = this.state;

    return (
      <>
        <Header />
        {!isMobile && <Hero />}
        <Service data={Services} />
        <Portfolio data={Portfolios} />
        <Advantage data={Advantages} />
        <Testimonial data={Testimonials} />
        <Discuss />
        <Footer />
      </>
    );
  }
}
