/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from "react";
import logo from "../assets/images/logo-removebg-preview.png";

export default function BrandIcon() {
  return (
    <img
      src={logo}
      alt="logo"
      width={150}
      className="bg-theme-purple rounded-md p-2"
    />
  );
}
