import React, { useState, useEffect } from "react";
// import { FaGlobe } from "react-icons/fa";
import Typewriter from "typewriter-effect";
import HeroAnimation from "./HeroAnimation";
import left from "../assets/images/left.png";
import right from "../assets/images/right.png";
import bg from "../assets/images/BG.png";

export default function Hero() {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="relative h-[80vh] overflow-hidden ">
      <div className="absolute inset-0 flex transition-transform duration-1000">
        {/* Left Section */}
        <div
          className={`bg-cover bg-center w-1/2 transform transition-all duration-1000 ${
            scrolling ? "-translate-x-full scale-110" : "translate-x-0"
          }`}
          style={{
            backgroundImage: `url(${left})`,
          }}
        >
          <div className="text-white p-12 pt-[10rem]">
            <h1 className="text-[3.5rem] leading-snug font-extrabold bg-clip-text text-[#3d52a0] bg-gradient-to-r from-white to-gray-300 drop-shadow-md">
              <Typewriter
                options={{
                  strings: [
                    "𝙳𝚒𝚜𝚌𝚘𝚟𝚎𝚛 𝚠𝚒𝚝𝚑 𝚞𝚜",
                    "𝙴𝚡𝚙𝚊𝚗𝚍 𝚢𝚘𝚞𝚛 𝚠𝚘𝚛𝚕𝚍",
                    "𝙴𝚡𝚙𝚕𝚘𝚛𝚎 𝚗𝚎𝚠 𝚑𝚘𝚛𝚒𝚣𝚘𝚗𝚜",
                  ],
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 50,
                }}
              />
            </h1>
            <p className="text-lg mt-6 text-gray-200">
              Our dynamic IT software company is dedicated to providing
              exceptional, customized solutions designed for your success.
              Collaborate with us for innovative software and IT strategies. We
              excel at empowering businesses in the digital age by offering an
              extensive range of services that foster innovation and enhance
              your operations.
            </p>
            <button
              type="button"
              className="mt-8 px-6 py-3 bg-[#3d52a0] hover:bg-purple-900 rounded-lg shadow-lg text-white font-semibold transition-transform transform hover:scale-105"
            >
              Learn More
            </button>
          </div>
        </div>
        {/* Right Section */}
        <div
          className={`bg-cover bg-red-200 bg-center flex justify-center items-center w-1/2 transition-transform duration-1000 ${
            scrolling ? "translate-x-full scale-110" : "translate-x-0"
          }`}
          style={{
            backgroundImage: `url(${right})`,
          }}
        >
          <img src={bg} width={600} height={600} alt="img" />
        </div>
      </div>

      {/* Overlay Animation */}
      <div
        className={`absolute inset-0 flex items-center justify-center text-center z-10 transition-opacity duration-1000 ${
          scrolling ? "opacity-100" : "opacity-0"
        }`}
      >
        <HeroAnimation />
      </div>
    </div>
  );
}

const styles = `
  @keyframes rotate {
    0% {
      transform: rotateX(0deg) rotateY(0deg);
    }
    25% {
      transform: rotateX(90deg) rotateY(90deg);
    }
    50% {
      transform: rotateX(180deg) rotateY(180deg);
    }
    75% {
      transform: rotateX(270deg) rotateY(270deg);
    }
    100% {
      transform: rotateX(360deg) rotateY(360deg);
    }
  }

  .rotate {
    animation: rotate 40s linear infinite;
  }

  @keyframes slow-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes textSlideIn {
    0% {
      opacity: 0;
      transform: translateY(-30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

document.head.insertAdjacentHTML("beforeend", `<style>${styles}</style>`);